
import './header.css';
import {
    Link
  } from "react-router-dom";
import menuBtn from './img/menu-btn.svg';
import closeBtn from './img/close.svg';
import React from 'react';

class Header extends React.Component{

    constructor(){
        super();
        this.state={
            isOpen: false
        };
    }

    componentDidMount(){
        this.setState({isOpen:false});
    }
    updateOpen=()=>{
        let currentState = this.state.isOpen;
        this.setState({isOpen:!currentState});
    }

    closeNav=()=>{
        this.setState({isOpen:false});
    }
    render(){
        return(
            <header>
                <button className="mobile-btn" onClick={this.updateOpen}>
                        {this.state.isOpen?
                            <img src={closeBtn} alt="" height="50px"/>
                            :
                            <img className="menu-btn" src={menuBtn} alt="hamburguer" height="48px"/>}
                        
                    </button>
                <nav className={this.state.isOpen?"open-menu":"menu"}>
                    
                    <div className="menu-header">
                        {/*<div><h2>Carmela Trujillo</h2></div>*/}
                        <button className="closebtn" onClick={this.closeNav}>×</button>
                    </div>
                    {/*
                    <ul>
                        <div className="social-media">
                            <li className="nav-item">
                                <a href="https://www.facebook.com/profile.php?id=100008762894890" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/facebook.png' alt="icono-facebook" height="32px"/>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="https://www.instagram.com/carmela_trujillo_escritora/?igshid=au7bn8y8sufb" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/instagram.png' alt="icono-instagram" height="32px"/>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="https://libroscarmelatrujillo.blogspot.com/" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/blogspot.png' alt="icono-blogspot" height="32px"/>
                                </a>
                            </li>
                        </div>
                        <div className="nav-menu">
                            <li className="nav-item">
                                <Link to="/" onClick={this.closeNav}>Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/libros" onClick={this.closeNav}>Libros</Link>
                            </li>
                            /*
                            <li className="nav-item dropdown">
                            <div>Libros</div>
                            <div className="dropdown-menu">
                                <Link className="dropdown-item" to="/infantil-juvenil">Infantil y juvenil</Link>
                                <Link className="dropdown-item" to="/adultos">Adultos</Link>
                            </div>
                            </li>*/
                            
/*
                            <li className="nav-item">
                                <Link to="/traducciones" onClick={this.closeNav}>Traducciones</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/premios" onClick={this.closeNav}>Premios</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/galeria" onClick={this.closeNav}>Galería</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contacto" onClick={this.closeNav}>Contacto</Link>
                            </li>
                        </div>
                        
                    </ul>*/}
                    <div>
                    <div className="social-media">
                            <div className="nav-item">
                                <a href="https://www.facebook.com/profile.php?id=100008762894890" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/facebook.png' alt="icono-facebook" height="32px"/>
                                </a>
                            </div>
                            <div className="nav-item">
                                <a href="https://www.instagram.com/carmela_trujillo_escritora/?igshid=au7bn8y8sufb" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/instagram.png' alt="icono-instagram" height="32px"/>
                                </a>
                            </div>
                            <div className="nav-item">
                                <a href="https://libroscarmelatrujillo.blogspot.com/" target="_blank" rel="nofollow noreferrer">
                                    <img src='images/blogspot.png' alt="icono-blogspot" height="32px"/>
                                </a>
                            </div>
                        </div>
                        <div className="nav-menu">
                            <div className="nav-item">
                                <Link to="/" onClick={this.closeNav}>Inicio</Link>
                            </div>
                            <div className="nav-item">
                                <Link to="/libros" onClick={this.closeNav}>Libros</Link>
                            </div>
                                        
                            <div className="nav-item">
                                <Link to="/traducciones" onClick={this.closeNav}>Traducciones</Link>
                            </div>
                            <div className="nav-item">
                                <Link to="/premios" onClick={this.closeNav}>Premios</Link>
                            </div>
                            <div className="nav-item">
                                <Link to="/galeria" onClick={this.closeNav}>Galería</Link>
                            </div>
                            <div className="nav-item">
                                <Link to="/contacto" onClick={this.closeNav}>Contacto</Link>
                            </div>
                        </div>
                    </div>
                </nav>                
            </header>
        );
    }
    
}

export default Header;