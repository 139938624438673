
import React from 'react';
import './item-book.css';

class ItemBook extends React.Component{
    constructor(props){
        super();
        this.state={
            imagen: '', slug: '', id: 0, url: ''

        }
    }

    componentDidMount(){
        this.setState({
            id: this.props.info.id,
            imagen: this.props.info.imagen,
            slug: this.props.info.slug,
            url: this.props.info.urlPath
        });
    }
    render(){
        return (
            <div className="item-book">
                <div className="item-container">
                    <div className="item">
                        <div className="image">
                            <img src={'./images/portadas/'+this.state.imagen} alt={this.state.slug} />
                        </div>                    
                    </div>
                    <div className="details">
                        <a className="btn" href={this.state.url} rel="noreferrer nofollow" target="_blank">Detalles</a>
                    </div>
                </div>
                <div className="modal-book">
                    <div className="modal-content">
                        Modal
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemBook;