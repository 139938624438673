

import React from 'react';

class Dashboard extends React.Component{
    render(){
        return(
            <div>Hola, Bienvenido al panel de administración</div>
        );
    }
}

export default Dashboard;