import React from 'react';
import './contacto.css';

class Contacto extends React.Component{

    constructor(){
        super();
        this.state ={
            name:'',
            email: '',
            subject: '',
            message: '',
            nombre: false,
            remite: false,
            asunto: false,
            mensaje: false,
            disabled: true
        }
    }

    handleSubmit=(event)=>{
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify({ 
                nombre: this.state.name, 
                remite: this.state.email, 
                asunto: this.state.subject, 
                mensaje: this.state.message })
        };
        fetch(process.env.REACT_APP_UrlApiEmail, requestOptions)
            .then(response => {
                console.log('Status: '+response.status);
                this.setState({
                    name:'',
                    email: '',
                    subject: '',
                    message: '',
                    nombre: false,
                    remite: false,
                    asunto: false,
                    mensaje: false,
                    disabled: true
                });
            })
            .catch(error => {
                console.log('error: '+error);
                console.log('data: '+requestOptions['body'])});
        
    }


    updateDisabledState=()=>{
        let disabledState = this.state.nombre&&this.state.remite&&this.state.asunto&&this.state.mensaje;
        this.setState({disabled:!disabledState});
        
    }

    checkName=(e)=>{
        //e.preventDefault();
        if(e.target.value.length>0|| e.target.value!==""){
            this.setState({nombre: true}, ()=>{
                this.updateDisabledState();
            });
            this.setState({name: e.target.value});
        }else{
            if(e.target.value===""||e.target.value.length===0){
                this.setState({nombre: false}, ()=>{
                    this.updateDisabledState();
                })
            }
        }
    }

    checkEmail=(e)=>{
        e.preventDefault();
        if(e.target.value.length>0 || e.target.value!=="" ){
            this.setState({remite: true},()=>{
                this.updateDisabledState();
            });
            this.setState({email: e.target.value});
        }else{
            if(e.target.value===""||e.target.value.length===0){
                this.setState({remite: false}, ()=>{
                    this.updateDisabledState();
                })
            }
        }
    }

    checkSubject=(e)=>{
        e.preventDefault();
        if(e.target.value.length>0|| e.target.value!==""){
            this.setState({asunto: true},()=>{
                this.updateDisabledState();
            });
            this.setState({subject: e.target.value});
        }else{
            if(e.target.value==="" ||e.target.value.length===0){
                this.setState({asunto: false}, ()=>{
                    this.updateDisabledState();
                })
            }
        }
    }

    checkMessage=(e)=>{
        e.preventDefault();
        if(e.target.value.length>0|| e.target.value!==""){
            this.setState({mensaje: true},()=>{
                this.updateDisabledState();
            });
            this.setState({message: e.target.value});
        }else{
            if(e.target.value===""||e.target.value.length===0){
                this.setState({mensaje: false},()=>{
                    this.updateDisabledState();
                })
            }
        }
    }

    defaultValue = () =>{

    }

    render(){
        return(
            <main id="contacto" className="contacto">
                <article>
                    <h2 className="article-title">Contacto</h2>
                    <div className="article-content">
                        <hr/>
                        <p>Si tienes cualquier consulta, escríbenos a librostrujillo@gmail.com y trataremos de responderte lo antes posible.</p>
                        <form className="contact-form" onSubmit={this.handleSubmit}>
                            <div className="label-name">
                                <label htmlFor="nombre"></label>
                                <input id="input" type="text" value={this.state.name} name="nombre" onChange={this.checkName} placeholder="Nombre*"></input>
                            </div>
                            <div className="label-email">
                                <label htmlFor="email"></label>
                                <input type="email" name="email" value={this.state.email} onChange={this.checkEmail} placeholder="Email*"></input>
                            </div>
                            <div className="label-subject">
                                <label htmlFor="asunto"></label>
                                <input type="text" name="asunto" value={this.state.subject} onChange={this.checkSubject} placeholder="Asunto"></input>
                            </div>
                            <div className="label-message">
                                <label htmlFor="mensaje"></label>
                                <textarea name="mensaje" value={this.state.message} onChange={this.checkMessage} placeholder="¿Cómo te puedo ayudar?" cols="30" rows="10"></textarea>
                            </div>
                            <div>
                                <input type="submit"  value="Enviar" disabled={this.state.disabled}/>
                            </div>
                        </form>
                    </div>
                </article>
            </main>      
        )
    }
}

export default Contacto;