import React from 'react';
import './home.css';
import News from '../noticias/noticias';
class Home extends React.Component{

    componentWillUnmount(){
    }
    render(){
        return(
            <main className="home">
                <div className="image-container">
                    <img className="imagen-portada" src="images/rodi_agenda_2.jpg" alt="rodi_agenda" />
                    <h1 className="page-title">Carmela Trujillo</h1>
                </div>
                <article>
                    <div className="article-content">
                        <div className="text-content">
                            <img src="images/Carmela Trujillo 2023 B-N.jpg" alt="carmela" />
                            <p>Nací el invierno de 1966 en un pueblo de Cáceres llamado Talayuela y era tan pequeñita que cabía en una caja de zapatos (eso me dijo mi madre). Más tarde, toda la familia nos fuimos a Sabadell (Barcelona), donde he vivido siempre y donde comencé a escribir mis primeros cuentos con tan solo ocho años. Desde 2015 resido también en Logroño (La Rioja).</p>
                            <p>Estoy casada, tengo tres hijos, un perro flaco, una gata sorda y casi treinta libros publicados (algunos han sido traducidos al catalán, al valenciano, al euskera y al italiano). También he recibido premios y menciones en literatura infantil, relato corto, novela y poesía, tanto en España como en Sudamérica, y he participado en libros de textos infantiles y en antologías de relato corto y poesía.</p>
                            <p>Soy licenciada en Filosofía y Ciencias de la Educación</p>
                        </div>
                    </div>
                </article>
                <News/>
            </main>      
        );
    }
}

export default Home;