import React from 'react';

class NoMatch extends React.Component{
    render(){
        return(
            <div>Fallo en matrix</div>
        );
    }
}

export default NoMatch;