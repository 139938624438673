import React from 'react';
import ItemBook from '../item-book/item-book';
import Loader from '../loader/loader';
import BOOKS from '../../constants/books';
import './libros.css';

class Libros extends React.Component{
    constructor(){
        super();
        this.state={
            books:[],
            errorMessage:'',
            loading: true
        }
    }
	
	componentDidMount() {
    // Simple GET request using fetch
        fetch(process.env.REACT_APP_UrlApiBooks)
            .then(response => response.json())
            .then(data => {
                this.setState({loading: false})
                this.setState({books: data});
                console.log(this.state.books);
            }).catch(error => {
                this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
                this.setState({loading: false})
                this.setState({books: BOOKS});
            });
    }

    componentWillUnmount(){
        console.log('libros will unmount');
        this.setState({books:[]});
        this.setState({errorMessage:''});
        this.setState({loading: false});
    }
    render(){
        return(
            <main id="libros">
                <article>
                    <h2 className="article-title">{this.props.original?'Libros':'Traducciones'}</h2>
                    <div className="article-content">
                        {this.state.loading?
                            <Loader/>
                            :
                            <div className="books-grid">
                            {
                                this.state.books.filter(book=>book.original===this.props.original).sort((a, b)=>b.ano-a.ano).map(book=>
                                    <ItemBook key={book.id} info={book}/>
                                )
                            } 
                            </div>
                        }
                    </div>
                </article>
            </main>
        )
    }
}

export default Libros;