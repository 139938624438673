import Header from './components/header/header';
import Footer from './components/footer/footer'
import { Switch, Route } from 'react-router-dom';
import Home from './components/home/home';
import Libros from './components/libros/libros';
import Premios from './components/premios/premios';
import Galeria from './components/galeria/galeria';
import Contacto from './components/contacto/contacto';
import NoMatch from './404';
import Dashboard from './components/admin/dashboard';
import './constants/config';
import { Helmet } from 'react-helmet';
const noticias = [

];

const  App = () =>{

  return (
    <Switch>
        <Route exact path="/">
            <Helmet>
                <meta name="title" content="Inicio | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="https://carmelatrujillo.com/" />
                <title>Inicio | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Home />
            <Footer news={noticias}/>
        </Route>
        <Route path="/premios">
            <Helmet>
                <meta name="title" content="Premios | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="http://carmelatrujillo.com/premios" />
                <title>Premios | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Premios />
            <Footer news={noticias}/>
        </Route>
        <Route path="/libros">
            <Helmet>
                <meta name="title" content="Libros | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="http://carmelatrujillo.com/libros/" />
                <title>Libros | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Libros original={1} categoria={1} />
            <Footer news={noticias}/>
        </Route>
        <Route path="/traducciones">
            <Helmet>
                <meta name="title" content="Traducciones | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="http://carmelatrujillo.com/traducciones/" />
                <title>Traducciones | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Libros original={0} />
            <Footer news={noticias}/>
        </Route>
        <Route path="/galeria">
            <Helmet>
                <meta name="title" content="Galería | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="https://carmelatrujillo.com/galeria" />
                <title>Galería | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Galeria />
            <Footer news={noticias}/>
        </Route>
        <Route path="/contacto">
            <Helmet>
                <meta name="title" content="Contacto | Carmela Trujillo" data-react-helmet="true"/>
                <link rel="canonical" href="http://carmelatrujillo.com/example" />
                <title>Contacto | Carmela Trujillo</title>
            </Helmet>
            <Header/>
            <Contacto />
            <Footer news={noticias}/>
        </Route>
        <Route path="/admin">
            <Dashboard/>
        </Route>
        <Route path="*">
            <NoMatch />
        </Route>
    </Switch>
  );
}

export default App;
