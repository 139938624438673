import React from 'react';
import './noticias.css';

class News extends React.Component{
    render(){
        return(
            <article>
                    <div className="article-content">
                        News
                    </div>
                </article>
        );
    }
}

export default News;