const BOOKS = [
    { id: 1, imagen: 'En_el_desierto_by_Carmela_Trujillo_edited.jpg', slug: 'en-el-desierto', url: 'https://www.combeleditorial.com/es/libro/la-madriguera-en-el-desierto_978-84-9101-571-0', original: true },
    { id: 2, imagen: 'Al_desert_edited.jpg', slug: 'al-desert', url: 'https://www.combeleditorial.com/ca/llibre/el-cau-al-desert_978-84-9101-570-3', original: false },
    { id: 3, imagen: 'Pero_esto_que_es-_by_Carmela_Trujillo.jpg', slug: 'pero-esto-que-es', url: 'https://www.editorialbambu.com/es/libro/pero-esto-que-es_80070032/', original: true },
    { id: 4, imagen: 'Martina_by_Carmela_Trujillo.jpg', slug: 'martina', url: 'https://www.harpercollinsiberica.com/hqn/martina-ganadora-vii-premio-internacional-hqn-detail', original: true },
    { id: 5, imagen: 'El_heroe_de_la_piscina_by_Carmela_Trujillo.jpg', slug: 'el-heroe-de-la-piscina', url: 'https://www.editorialbambu.com/es/libro/el-heroe-de-la-piscina_80070031/', original: true },
    { id: 6, imagen: 'Desde_el_otro_lado_edited.jpg', slug: 'desde-el-otro-lado-del-espejo', url: 'https://lektu.com/l/kuei-ediciones/desde-el-otro-lado/11075', original: true },
    { id: 7, imagen: 'Yo_queria_ser_saltimbanqui_by_Carmela_Trujillo.jpg', slug: 'yo-queria-ser-saltimbanqui', url: 'https://www.combeleditorial.com/es/libro/yo-queria-ser-saltimbanqui_978-84-9101-421-8', original: true },
    { id: 8, imagen: 'Jo_volia_ser_saltimbanqui_by_Carmela_Trujillo.jpg', slug: 'jo-volia-ser-saltimbanqui', url: 'https://www.combeleditorial.com/ca/llibre/jo-volia-ser-saltimbanqui_978-84-9101-420-1', original: false },
    { id: 9, imagen: 'Hermana_gata_by_Carmela_Trujillo.jpg', slug: 'hermana-gata', url: 'https://cosquillaseditorial.com/producto/hermana-gata/', original: true },
    { id: 10, imagen: 'Cuantas_reinas_caben_en_una_casa-_by_Carmela_Trujillo.jpg', slug: 'cuantas-reinas-caben-en-una-casa', url: 'https://www.ramaraga.com/inicio/cuantas-reinas-caben-en-una-casa.html', original: true },
    { id: 11, imagen: 'castillo.jpg', slug: 'castillo-que-castillo', url: 'https://cosquillaseditorial.com/castilloque-castillo/', original: true },
    { id: 12, imagen: 'Gaztelu.jpg', slug: 'Gaztelu', url: 'https://www.harrietediciones.es/index.php/producto/gaztelua-zein-gaztelu/?lang=eu', original: false },
    { id: 13, imagen: 'portada_guardando_las_apariencias.png', slug: 'guardando-las-apariencias', url: 'https://torredelis.quares.es/apex/quares/r/landingweb/detalle-producto?p2_id=18854&session=31385754739159', original: true },
    { id: 14, imagen: 'Chavela_Vargas_edited.jpg', slug: 'chavela-vargas', url: 'https://librosdeidayvuelta.com/editorial-libros-de-ida-y-vuelta/26-chavela-vargas.html', original: true},
    { id: 15, imagen: 'y_de_repente.png', slug: 'y de repente echandola de menos', url: null, original: true},
    { id: 16, imagen: 'la_lluvia_llego_con_gabriela.jpg', slug: 'lluvia-llego-gabriela', url: 'https://algareditorial.com/calcetin/6758-la-lluvia-llego-con-gabriela-9788498457964.html', original: true},
    { id: 17, imagen: 'en_las_nubes.jpg', slug: 'en-las-nubes', url: 'https://www.anayainfantilyjuvenil.com/libro/el-duende-verde/en-las-nubes/', original: true},
    { id: 18, imagen: 'lo-recuerdo-perfectamente.jpg', slug: 'recuerdo-perfectamente', url: 'https://www.anayainfantilyjuvenil.com/libro/el-duende-verde/lo-recuerdo-perfectamente-9788466794954/', original: true},
    { id: 19, imagen: 'cuando_las_vacas.jpg', slug: 'vacas-toman-te', url: 'https://libreria.sanpablo.es/libro/cuando-las-vacas-toman-el-te-de-las-5_5118', original: true},
    { id: 20, imagen: 'esto_no_puede_seguir_asi.jpg', slug: 'esto-no-puede-seguir-asi', url: 'https://www.anayainfantilyjuvenil.com/libro/el-duende-verde/esto-no-puede-seguir-asi-9788466762953/', original: true},
    { id: 21, imagen: 'mavorreixo.jpeg', slug: 'avorreixo', url: 'https://www.combeleditorial.com/ca/llibre/mavorreixooo_978-84-9101-348-8', original: false},
    { id: 22, imagen: 'nena-passejar-gossoss.jpg', slug: 'nena-volia-passejar-gossos-petits', url: 'https://animallibres.cat/la-formiga/7687-la-nena-que-volia-passejar-gossos-petits-9788416844517.html', original: false},
    { id: 23, imagen: 'aixo_no_pot_continuar_aixi.jpg', slug: 'aixo-no-pot-continuar-aixi', url: 'https://www.anayainfantilyjuvenil.com/libro/el-duende-verde-c-valenciana/aixo-no-pot-continuar-aixi-9788466778701/', original: false},
    { id: 24, imagen: 'nina-perros-pequenos.jpg', slug: 'la niña que queria pasear perros pequeños', url: 'https://algareditorial.com/calcetin/7058-la-nina-que-queria-pasear-perros-pequenos-9788491420903.html', original: true},
    { id: 21, imagen: 'aburro.jpeg', slug: 'aburro', url: 'https://www.combeleditorial.com/es/libro/me-aburrooo_978-84-9101-349-5', original: true},
]

export default BOOKS;