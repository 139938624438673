
import React from 'react';
import './footer.css';
class Footer extends React.Component{
    render(){
        return(
            <footer>
                <div className="footer-content">
                    <div className="content">
                        <h3>Contacto</h3>
                        <p>
                            librostrujillo@gmail.com
                        </p>
                    </div>
                    <div className="content">
                            <h3>Redes sociales</h3>
                            <div className="social-networks">
                                <div>
                                    <a href="https://www.facebook.com/profile.php?id=100008762894890" target="_blank" rel="nofollow noreferrer">
                                        <img src="images/facebook_footer.png" alt="facebook" width="30px" />
                                    </a>
                                </div>|
                                <div>
                                    <a href="https://www.instagram.com/carmela_trujillo_escritora/?igshid=au7bn8y8sufb" target="_blank" rel="nofollow noreferrer">
                                        <img src="images/instagram_footer.png" alt="instagram" width="30px" />
                                    </a>
                                </div>|
                                <div>
                                    <a href="https://libroscarmelatrujillo.blogspot.com/" target="_blank" rel="nofollow noreferrer">
                                        <img src="images/blogspot_footer.png" alt="blogspot" width="30px" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="sub-footer">
                <p>&copy;2021 carmelatrujillo.com | Powered By <a href="https://www.linkedin.com/in/rubenpallaresfernandez" rel="noreferrer nofollow" target="_blank">Rubén Pallarés</a></p>
                </div>
                
            </footer>
        );
    }
}

export default Footer;