import React from 'react';
import './premio.css';
class Premio extends React.Component {

    constructor(props) {
        super(props);
        this.state={}
    }


    render() {
        return (
            <div class="premio">
                <div className="premio-content">
                    <div class="row-container descripcion"> 
                        <div className="center-text">
                            <p>"{this.props.data.titulo}"</p>
                            <p>({this.props.data.categoria})</p>
                            <p>{this.props.data.descripcion}</p>
                            <p>({this.props.data.ano})</p>
                        </div>
                    </div>
                    <div className="row-container image-premio-container">
                        {/*<div class="thumb" style={{backgroundImage: 'url(\'./imagen/'+this.props.data.imagen+''}} title={this.props.data.alt}></div>*/}
                        <img class="thumb" src={'./images/portadas/'+this.props.data.imagen} alt={this.props.data.alt} title={this.props.data.alt}/>
                    </div>
                   {/* <img class="miniatura" src={'./images/'+this.props.data.imagen} alt={this.props.data.alt}/>*/}
                </div>
            </div>
        );
    }
}

export default Premio;