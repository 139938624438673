import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


class Galeria extends React.Component{
    constructor(){
        super();
        this.state={
            Images:[],
            errorMessage:'',
            loading: true
        }
    }
    componentDidMount() {
        // Simple GET request using fetch
            fetch(process.env.REACT_APP_UrlApiGaleria)
                .then(response => response.json())
                .then(data => {
                    let temp=[];
                    this.setState({loading: false});
                    data.forEach(image => {
                        temp.push(
                            {
                                'original': image['imagen'],
                                'thumbnail': image['thumbnail'],
                                'description':image['descripcion']});
                            }
                        
                    );
                    this.setState({Images: temp});

                }).catch(error => {
                    this.setState({ errorMessage: error.toString() });
                    this.setState({loading: false})
                });
        }
    render(){
        return(
            <main>
                <ImageGallery items={this.state.Images} />
            </main>
        );
    }
}

export default Galeria;