import React from 'react';
import './premios.css';
import Premio from '../premio/premio';

class Premios extends React.Component{
    constructor(props){
        super(props);
            this.state={
                premios:[],
                loading: true,
            }
    }

    componentDidMount() {
    // Simple GET request using fetch
        fetch(process.env.REACT_APP_UrlApiPremios)
            .then(response => response.json())
            .then(data => {
                this.setState({loading: false})
                this.setState({premios: data});
                console.log(this.state.premios);
            }).catch(error => {
                this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
                this.setState({loading: false})
                //this.setState({books: BOOKS});
            });
    }

    render(){
        return(
            <main id="premios">
                <article>
                    <h2 className="article-title">Premios</h2>
                    <div className="article-content-premios">
                        
                            {this.state.premios.sort((a,b) => b.ano - a.ano).map(premio=>(
                                <Premio key={premio.id} data={premio}/>
                            ))
                            }
                        
                    </div>
                </article>
            </main>
        )
    }
}

export default Premios;